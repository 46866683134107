import React, { useContext, useMemo, useState } from "react";
import { ReactComponent as Logo } from "../static/streamy-mark.svg";
import { AuthContext, Button, Form, httpPost } from "@streamy/common";
import { useForm } from "react-hook-form";
import { User } from "@sentry/react";
import { useLocation } from "react-router-dom";

interface LoginFormData {
    email: string;
    password: string;
}

interface AuthResponse {
    accessToken: string;
    refreshToken: string;
    user: User;
}

interface LogoutMessage {
    type: "info" | "error";
    message: string;
}

const Login = () => {
    const { login } = useContext(AuthContext);
    const { search } = useLocation();

    const [errorMessage, setErrorMessage] = useState("");
    const { register: loginRegister, handleSubmit: handleLoginSubmit, errors } = useForm<LoginFormData>();

    const handleLogin = (formData: LoginFormData) => {
        setErrorMessage("");
        httpPost<AuthResponse>("auth/login", JSON.stringify(formData))
            .then((data) => {
                login(data.accessToken, data.refreshToken, data.user);
            })
            .catch((e) => {
                setErrorMessage(e.body.Message.replace("UnauthorizedError: ", ""));
            });
    };

    const message = useMemo((): LogoutMessage | undefined => {
        const s = new URLSearchParams(search);

        if (s.has("t") && s.has("msg") && s.get("a") === "logout") {
            return {
                type: s.get("t"),
                message: s.get("msg"),
            } as LogoutMessage;
        }

        return undefined;
    }, [search]);

    return (
        <div className="min-h-screen bg-gray-300 flex flex-col justify-center py-12 px-8 mobile:px-6">
            <div className="mobile:mx-auto mobile:w-full mobile:max-w-md">
                <div className="flex justify-center items-center bg-brand-black mx-auto h-12 w-12 rounded-sm">
                    <Logo />
                </div>
                <h1 className="mt-6 text-center text-4xl leading-9 font-extrabold text-gray-900">
                    Sign in to your account
                </h1>
            </div>
            <div className="mt-8 max-w-lg mx-auto w-full">
                <div className="bg-white py-8 px-4 shadow rounded-lg mobile:px-10">
                    <form onSubmit={handleLoginSubmit(handleLogin)}>
                        <Form.FormInput
                            label="Email address"
                            name="email"
                            autoComplete="email"
                            ref={loginRegister({ required: "Field is required" })}
                        />
                        {errors.email && (
                            <span role="alert" className="text-red-500">
                                {errors.email.message}
                            </span>
                        )}

                        <Form.FormInput
                            label="Password"
                            type="password"
                            name="password"
                            autoComplete="current-password"
                            ref={loginRegister({ required: "Field is required" })}
                        />
                        {errors.password && (
                            <span role="alert" className="text-red-500">
                                {errors.password.message}
                            </span>
                        )}
                        {/* <div className="mt-6 flex items-center justify-between">
                            <div className="text-sm leading-5">
                                <a
                                    href="#"
                                    className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                                >
                                    Forgot your password?
                                </a>
                            </div>
                        </div> */}

                        {errorMessage && (
                            <div className="bg-red-500 text-white text-xl p-3 mobile:text-lg my-2">{errorMessage}</div>
                        )}

                        {message && (
                            <div
                                className={`bg-${
                                    message.type === "error" ? "red" : message.type === "info" ? "blue" : ""
                                }-500 text-white text-xl p-3 mobile:text-lg my-2`}
                            >
                                {message.message}
                            </div>
                        )}

                        <div className="mt-6">
                            <Button className="w-full">Sign in</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
