import MuxPlayer from "@mux/mux-player-react";
import { Button, Event, httpGet, httpPost } from "@streamy/common";
import React, { useCallback, useState } from "react";
import useSWR from "swr";

type StreamsProps = {
    event: Event;
    className?: string;
};

export interface LiveStream {
    active_asset_id: string | undefined | null;
    recording: boolean;
    live_stream_id: string;
    playback_ids: [{ id: string; policy: "public" | "private" }];
    status: "active" | "deleted" | "idle";
    health: "ok";
    bitrate: "7324kbps";
    recent_asset_ids: [string];
    max_continuous_duration: number;
    name: string;
    latency_mode: string;
    reconnect_window: number;
    stream_key: string;
    timestamp_created: number;
}

export interface VideoAsset {
    video_asset_id: string;
    event_id: string;
    name: string;
    timestamp_created: number;
    webhook_timestamp_updated: number;
    playback_ids: [{ id: string; policy: string }];
    status: string;
    duration: number;

    created_at: string;
    max_stored_resolution: string;
    max_stored_frame_rate: string;
    aspect_ratio: string;
    is_live: boolean;
    live_stream_id: string;
    master: { status: "ready" | "preparing" | "error"; url: string } | null | undefined;
    master_access: "none" | "temporary";
}

const useLiveViewersData = ({ asset_id, active = false }: { asset_id: string; active: boolean }) => {
    const { data: viewers } = useSWR<VideoAsset>("admin/video-asset/" + asset_id + "/viewers", httpGet, {
        refreshInterval: active ? 5000 : 0,
    });
    return viewers;
};

const StreamAsset = ({
    asset_id,
    active = false,
    event_id,
    revalidate,
}: {
    asset_id: string;
    active: boolean;
    event_id: string;
    revalidate: CallableFunction;
}) => {
    const { data: videoAsset } = useSWR<VideoAsset>("admin/video-asset/" + asset_id, httpGet, {
        refreshInterval: active ? 5000 : 60000,
    });

    const [isPublishingHls, setIsPublishingHls] = useState(false);

    const viewers = useLiveViewersData({ asset_id: asset_id, active: active });

    const playbackId = videoAsset?.playback_ids?.find((value) => value.policy === "public")?.id;

    const onPublishStream = useCallback(() => {
        if (window.confirm("Are you sure you want to PUBLISH this stream?")) {
            // publish
            const hls_url = "https://stream.mux.com/" + playbackId + ".m3u8";
            if (!!playbackId) {
                setIsPublishingHls(true);
                return httpPost("admin/events/" + event_id + "/publish-hls", JSON.stringify({ hls_url: hls_url }))
                    .catch((err) => {
                        setIsPublishingHls(false);
                        console.log(err);
                        alert("publish failed.");
                    })
                    .finally(() => {
                        revalidate();
                        setIsPublishingHls(false);
                    });
            } else {
                alert("no public stream found");
            }
        }
    }, [event_id, playbackId, revalidate]);

    if (!videoAsset) {
        return (
            <div className={"grid grid-cols-8 w-full border-2 border-gray-200 rounded p-2 my-2 bg-blue-200"}>
                loading...
            </div>
        );
    }

    return (
        <div
            className={
                active
                    ? "grid grid-cols-8 w-full border-2 border-gray-200 rounded p-2 my-2 bg-blue-200"
                    : "grid grid-cols-8 w-full border-2 border-gray-200 rounded p-2 my-2 "
            }
        >
            {active && (
                <p className="text-2xl col-span-8 mb-2">
                    Last active stream:
                    <br />
                </p>
            )}
            <div className="col-span-3 pr-5">
                <MuxPlayer streamType="live:dvr" key={playbackId + videoAsset?.status} playbackId={playbackId} />
            </div>
            <div className="col-span-4 ">
                <p className={videoAsset?.is_live ? "" : ""}>is live now: {videoAsset?.is_live ? "yes" : "no"}</p>
                <p>viewers: {JSON.stringify(viewers)}</p>
                <p>mux id: {asset_id}</p>
                <p className="">
                    <code className="text-sm ">
                        HLS URL: {!!playbackId ? "https://stream.mux.com/" + playbackId + ".m3u8" : "n/a"}
                    </code>
                </p>
                <p>status: {videoAsset?.status}</p>
                <p>duration: {videoAsset?.duration}</p>
                <p>aspect: {videoAsset?.aspect_ratio}</p>
                <p>resolution: {videoAsset.max_stored_resolution}</p>
            </div>

            <div className="grid gap-10 justify-end content-around col-end-auto col-span-1 mt-auto">
                <div>
                    <Button
                        onClick={() => {
                            onPublishStream();
                        }}
                        className="w-48 text-sm"
                        color="green"
                    >
                        {!isPublishingHls ? "Publish(w pausing)" : "publishing..."}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export function Streams({ event, className = "" }: StreamsProps) {
    const { data: streams, revalidate: revalidateLiveStream, isValidating } = useSWR<LiveStream[]>(
        "admin/events/" + event.eventId + "/live-streams",
        httpGet,
        {
            refreshInterval: 10,
        }
    );
    //const streams = [...MOCK_STREAMS];
    const [isCreatingLivestream, setIsCreatingLivestream] = useState(false);

    const onCreateNewStream = useCallback(
        (name: string) => {
            // create new live stream
            //"/admin/events/{event_id}/live-stream
            // POST
            // stream_name
            setIsCreatingLivestream(true);
            return httpPost("admin/events/" + event.eventId + "/live-stream", JSON.stringify({ stream_name: name }))
                .catch((err) => {
                    setIsCreatingLivestream(false);
                })
                .finally(() => {
                    revalidateLiveStream();
                    setIsCreatingLivestream(false);
                });
        },
        [event.eventId, revalidateLiveStream]
    );

    return (
        <div className="ml-5">
            <div className="flex">
                <p className="text-sm leading-5 font-medium text-gray-500 truncate">Streams</p>
                <Button
                    onClick={() => onCreateNewStream("unnamed")}
                    color="green"
                    className={
                        "mb-2 uppercase text-1 addBtn ml-auto  " + (isCreatingLivestream ? " animate-pulse " : "")
                    }
                >
                    {isCreatingLivestream ? <>creating...</> : "Create new stream"}
                </Button>
                <Button onClick={() => {}} color="green" className="mb-2 uppercase text-1 addBtn ml-2">
                    Upload video
                </Button>
            </div>
            <div className={className}>
                {isValidating && !streams && "loading streams"}
                <div className="grid gap-5">
                    {streams
                        ?.sort((a, b) => b.timestamp_created - a.timestamp_created)
                        .map((stream) => {
                            const playbackId = stream.playback_ids.find((value) => value.policy === "public")?.id;
                            const isReeceivingVideo = stream.status === "active" && stream.recording;
                            return (
                                <div className="grid grid-cols-6 gap-5 border-2 border-gray-200 rounded p-5">
                                    <div
                                        className={
                                            isReeceivingVideo
                                                ? "text-white font-bold col-span-6 rounded p-2 bg-green-500"
                                                : "text-black font-bold col-span-6 rounded p-2 bg-gray-200"
                                        }
                                    >
                                        {isReeceivingVideo
                                            ? "Stream is receiving video"
                                            : "Stream is not receiving video"}
                                    </div>
                                    <div className="col-span-4">
                                        <p>
                                            Recording:{" "}
                                            {stream.recording ? (
                                                <>
                                                    <div className="rounded-full bg-red-600 w-4 h-4 inline-block animate-pulse" />{" "}
                                                    Yes
                                                </>
                                            ) : (
                                                "No"
                                            )}{" "}
                                        </p>
                                        <p>Status: {stream.status}</p>
                                        <p>Created: {new Date(stream.timestamp_created * 1000).toLocaleString()}</p>

                                        <div className="bg-gray-100 text-gray-500 py-2 px-2">
                                            {stream.stream_key ? (
                                                <>
                                                    <p>
                                                        <code className="text-sm">Stream key: {stream.stream_key}</code>
                                                    </p>
                                                    <p>
                                                        <code className="text-sm">
                                                            RTMPS URL: rtmps://global-live.mux.com:443/app
                                                        </code>
                                                    </p>
                                                    <p>
                                                        <code className="text-sm">
                                                            RTMP URL : rtmp://global-live.mux.com:5222/app
                                                        </code>
                                                    </p>
                                                    <p>
                                                        <code className="text-sm">
                                                            HLS URL:{" "}
                                                            {!!playbackId
                                                                ? "https://stream.mux.com/" + playbackId + ".m3u8"
                                                                : "n/a"}
                                                        </code>
                                                    </p>
                                                </>
                                            ) : null}
                                            {/*<p>Current viewers: {stream.currentViewers}</p>*/}

                                            <p>Reconnect window: {stream.reconnect_window}</p>
                                            <p>Max stream duration: {stream.max_continuous_duration}</p>
                                            <p>Last active asset: {stream.active_asset_id}</p>
                                        </div>

                                        {/*<p>Health: {stream.health}</p>*/}
                                        {/*<p>Bitrate in: {stream.bitrate}</p>*/}
                                    </div>
                                    <div className="col-span-2">
                                        <MuxPlayer
                                            streamType="live"
                                            key={playbackId + stream.status}
                                            playbackId={playbackId}
                                        />
                                        <p>
                                            <a
                                                rel="noreferrer"
                                                className="underline hover:no-underline hover:text-brand-blue-primary"
                                                target="_blank"
                                                href={
                                                    "https://dashboard.mux.com/organizations/u8vao2/environments/0bauig/video/live-streams/" +
                                                    stream.live_stream_id +
                                                    "/health"
                                                }
                                            >
                                                Watch stream health on mux.com
                                            </a>
                                        </p>
                                    </div>

                                    <div className="col-span-6 gap-1">
                                        {stream.active_asset_id && (
                                            <StreamAsset
                                                active={true}
                                                asset_id={stream.active_asset_id}
                                                event_id={event.eventId}
                                                revalidate={() => {}}
                                            />
                                        )}
                                        {stream.recent_asset_ids?.map((asset_id) => (
                                            <StreamAsset
                                                active={false}
                                                asset_id={asset_id}
                                                event_id={event.eventId}
                                                revalidate={() => {}}
                                            />
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}
