import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import "@streamy/common/styles/tailwind.css";
import "@streamy/common/styles/common.scss";
import { AuthContextProvider } from "@streamy/common";
import "./styles/_all.scss";
import * as serviceWorker from "./serviceWorker";
import { ErrorBoundary } from "./components/ErrorBoundary";

// TODO Add new DSN
Sentry.init({
    dsn: "https://635cae4fd9b6479e886c9bfa77a78d09@o404536.ingest.sentry.io/5425870",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
});

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary>
            <AuthContextProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </AuthContextProvider>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
