import React from "react";
import { Link, useParams } from "react-router-dom";
import { format } from "date-fns";
import { httpGet, LoadingView, View, Event } from "@streamy/common";
import useSWR from "swr";
import "@reach/dialog/styles.css";
import { Streams } from "../components/Streams";

const EventOverview = () => {
    const { id } = useParams<{ id: string }>();
    const { data } = useSWR<Event>("admin/events/" + id, httpGet);

    if (!data) {
        return <LoadingView label="Loading event..." />;
    }

    return (
        <>
            <View compact className="bg-gray-50">
                <div className="flex-1 relative pb-8 z-0 overflow-y-auto">
                    <div className="bg-white shadow">
                        <div className="px-4 mobile:px-6 desktop:max-w-6xl desktop:mx-auto desktop:px-8">
                            <div className="py-6 laptop:flex laptop:items-center laptop:justify-between desktop:border-t desktop:border-gray-200">
                                <div className="flex-1 min-w-0">
                                    <div className="flex items-center">
                                        <img
                                            className="h-15 w-15 rounded-full mobile:block object-cover"
                                            src={data.headerStandardUrl}
                                            alt=""
                                        />
                                        <div>
                                            <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 mobile:leading-9 mobile:truncate">
                                                {data.title}
                                            </h1>
                                            <dl className="mt-1 flex flex-row ml-3 mobile:flex-wrap">
                                                <dt className="sr-only">Event start time</dt>
                                                <dd className="flex items-center leading-5 text-gray-500 font-medium capitalize mr-6">
                                                    {format(new Date(data.startDatetimeSeconds * 1000), "dd MMM yyyy")}
                                                </dd>
                                                <dt className="sr-only">Event publish status</dt>
                                                <dd className="flex items-center leading-5 text-gray-500 font-medium mobile:mr-6 capitalize">
                                                    {data.visible ? (
                                                        <svg
                                                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-brand-green-primary"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-500"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    )}
                                                    {data.visible ? "Visible" : "Invisible"}
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-6 flex space-x-3 laptop:mt-0 laptop:ml-4">
                                    <Link
                                        to={`/events/${id}`}
                                        className="px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-sm text-white bg-brand-purple hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700 transition duration-150 ease-in-out"
                                    >
                                        Overview
                                    </Link>
                                    <Link
                                        to={`/events/${id}/edit`}
                                        className="px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-sm text-white bg-brand-purple hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700 transition duration-150 ease-in-out"
                                    >
                                        Edit event
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className=" mx-auto px-4 mobile:px-6 desktop:px-8">
                    <h2 className=" mx-auto mt-8 px-4 text-lg leading-6 font-medium font-global text-gray-900 mobile:px-6 desktop:px-8">
                        Video
                    </h2>

                    <div className="mt-2 grid">
                        <section className="bg-white overflow-hidden shadow rounded-lg flex flex-col col-span-3 p-5 mb-5">
                            <Streams event={data} />
                        </section>
                    </div>
                </section>
            </View>
        </>
    );
};

export default EventOverview;
