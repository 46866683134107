import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import { ReactComponent as Logo } from "./static/streamy-mark.svg";
import { Link, NavLink, Route, Switch, useHistory, useLocation } from "react-router-dom";
import "./app.scss";
import { LoadingView, AuthContext } from "@streamy/common";
import Login from "./views/Login";
import { useMediaQuery } from "react-responsive";
import EventStreams from "./views/EventStreams";

const Events = lazy(() => import("./views/Events"));
const EventOverview = lazy(() => import("./views/EventOverview"));
const EditEvent = lazy(() => import("./views/EditEvent"));
const CreateEvent = lazy(() => import("./views/CreateEvent"));

function App() {
    const { isLoggedin, user, logout, userFetchStatus } = useContext(AuthContext);
    const { search, pathname } = useLocation();
    const history = useHistory();
    const isMobile = useMediaQuery({ maxWidth: "768px" });
    const [hideMenu, setSHideMenu] = useState(isMobile);

    useEffect(() => {
        if (search && user) {
            history.push(pathname);
        }
    });

    if (["pending"].includes(userFetchStatus)) {
        return null;
    }

    if (!isLoggedin || !user) {
        return <Login />;
    }

    if (user.type !== "admin") {
        logout({ type: "error", text: "Insufficient account rights." });
    }

    return (
        <div className="min-h-screen flex bg-white">
            <div className=" flex flex-shrink-0 h-screen sticky top-0">
                <div
                    className="flex flex-col w-64 border-r border-gray-200 pb-4 bg-gray-100 relative overflow-hidden"
                    style={{
                        transform: !hideMenu ? "translateX(0)" : "translateX(-100%)",
                        transition: "transform 1s ease-in-ease-out margin 1s ease-in-ease-out",
                        //width: !showMenu ? 0 : "16rem",
                        marginLeft: !hideMenu ? 0 : "-16rem",
                    }}
                >
                    <div className="flex items-center flex-shrink-0">
                        <Link to="/" className="flex items-center bg-brand-black w-full">
                            <span className="px-3 flex-grow">
                                <Logo className="float-right" />
                            </span>
                            <div className="relative flex px-3 py-1 text-4xl font-semibold text-white bg-brand-green-primary font-highlight">
                                STREAMY
                            </div>
                        </Link>
                    </div>
                    <div className="h-0 flex-1 flex flex-col">
                        <div className="px-3 mt-6 relative inline-block text-left">
                            <p className="text-gray-900 leading-5 truncate">
                                {user.name.firstName} {user.name.lastName}
                            </p>
                        </div>

                        <nav className="px-3 mt-6 flex-grow flex flex-col">
                            <div className="space-y-1 flex-grow">
                                <NavLink
                                    to="/events"
                                    activeClassName="bg-gray-200"
                                    className="group flex items-center px-2 py-2 text-sm leading-5 font-medium rounded-md text-gray-900 focus:outline-none focus:bg-gray-50 transition ease-in-out duration-150"
                                >
                                    <svg
                                        className="mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                                        />
                                    </svg>
                                    Events
                                </NavLink>
                            </div>

                            <button
                                onClick={() => logout()}
                                className="group flex items-center px-2 py-2 text-sm leading-5 font-medium rounded-md text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:border focus:border-brand-blue-primary transition ease-in-out duration-150 justify-self-end"
                            >
                                Logout
                            </button>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-0 flex-1">
                <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 laptop:hidden">
                    <button
                        className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-100 focus:text-gray-600 laptop:hidden"
                        aria-label="Open sidebar"
                        onClick={() => {
                            setSHideMenu((prev) => !prev);
                        }}
                    >
                        <svg
                            className="h-6 w-6"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path d="M4 6h16M4 12h8m-8 6h16" />
                        </svg>
                    </button>
                </div>
                <Suspense fallback={<LoadingView />}>
                    <Switch>
                        <Route exact path="/">
                            <Events />
                        </Route>
                        <Route path="/events/create-event">
                            <CreateEvent />
                        </Route>
                        <Route path="/events/:id/edit">
                            <EditEvent />
                        </Route>
                        <Route path="/events/:id/streams">
                            <EventStreams />
                        </Route>
                        <Route path="/events/:id">
                            <EventOverview />
                        </Route>
                        <Route path="/events">
                            <Events />
                        </Route>
                    </Switch>
                </Suspense>
            </div>
        </div>
    );
}

export default App;
